// import clsx from "clsx";

import clsx from "clsx";
import LazyImage from "./LazyImage";
import Tag from "./Tag";

export default function CarListItem(props) {
  const car = props.car;
  const context = props.context ?? "grid";
  const href = props.href;

  return (
    <a
      href={href}
      className={clsx(
        "tw-relative tw-flex tw-flex-col before:tw-absolute before:tw-transition-colors hover:before:tw-bg-white/5 tw-select-none",
        !props.visible && "tw-hidden",
        props.className,
        context === "grid" && "before:tw--inset-3",
        context === "slider" && "tw-p-6 before:tw-inset-3",
      )}
    >
      {/* <pre>{JSON.stringify(car, null, 2)}</pre> */}
      <div className="tw-relative">
        <LazyImage
          outerClassName="tw-aspect-[4/3] tw-bg-gray-400"
          // innerClassName=""
          src={car.images[0]}
          alt={car.name_short}
        />
        {props.latest && (
          <Tag
            className="tw-absolute tw-top-2 tw-left-2"
            text="Neu eingetroffen"
          />
        )}
      </div>
      <div className="tw-flex-1 tw-mt-6 tw-mb-8 tw-flex tw-flex-col">
        <div className="w-o-format-h5">{car.name_short}</div>
        <div className="w-o-format-body-lead">{car.model_meta}</div>
        <dl className="tw-mt-auto tw-pt-4 tw-mb-4 tw-flex tw-flex-col tw-gap-2 w-o-format-body-small tw-text-creme tw-opacity-70 *:tw-w-full *:tw-flex *:tw-items-center *:tw-justify-between">
          <div>
            <dt>Kilometerstand</dt>
            <dd>{new Intl.NumberFormat("de-DE").format(car.mileage)} km</dd>
          </div>
          <div>
            <dt>Erstzulassung</dt>
            <dd>{car.initial_registration_formatted}</dd>
          </div>
          <div>
            <dt>Leistung</dt>
            <dd>
              {car.power_ps} PS ({car.power_kw} kW)
            </dd>
          </div>
        </dl>
        <div className="tw-mt-4 tw-pt-6 tw-border-t tw-border-white/15 tw-flex tw-items-center tw-justify-between">
          <span className="w-o-format-h5">
            {`€${new Intl.NumberFormat("de", {
              style: "currency",
              currency: "EUR",
            })
              .format(car.price)
              .replace(",00", ",–")
              .replace("€", "")
              .trim()}`}
          </span>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="tw-rotate-90 tw-size-4 tw-text-white/50"
          >
            <path
              d="M4.80019 15L12.0002 9L19.2002 15"
              stroke="currentColor"
              strokeWidth="3"
            />
          </svg>
        </div>
      </div>
    </a>
  );
}
